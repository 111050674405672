import {Component} from "react";
import {Link} from "react-router-dom";
import { isLoggedIn, fauxLogin, logout } from '../../components/utils/auth'
import { toast } from "react-toast";

const QuickLinksDropDownContent = (props) => {
    const { currentUser } = props;

    return <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-300 w-max">
                {currentUser && currentUser.isAuthenticated && (
                    <>
                        <li><Link to="/account">My Account</Link></li>
                        <li><Link to="/booking">My Bookings</Link></li>
                        <li><Link to="/passports">My Passports</Link></li>
                    </>
                )}
                <li><Link to="/about-us">About Us</Link></li>
                {/*
                <li><Link to="/popular-countries">Popular Countries</Link></li>
                <li><Link to="/popular-cities">Popular Cities</Link></li>
                <li><Link to="/popular-airports">Popular Airports</Link></li>
                <li><Link to="/airplane-categories">Airplane Categories</Link></li>
                <li><Link to="/all-countries">All Countries</Link></li>
                <li><Link to="/help">Help & FAQs</Link></li>
                <li><Link to="/contact-us">Contact Us</Link></li> */}
            </ul>
}


export const DevMenu = (props) => {

    return <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-300 w-max">
        <li className="text-red" >
            <Link to="/proj-plan">
                Project Status
            </Link>
        </li>
        <li><a onClick={() => fauxLogin(props.setCurrentUser)}>Faux login</a></li>
        <li><a onClick={isLoggedIn}>Check Session</a></li>
        <li><a onClick={() => logout(props.setCurrentUser)}>Log out</a></li>
        <hr />
        <li><a onClick={() => localStorage.clear()}>Clear localStorage</a></li>
        <li><a onClick={() => toast.hideAll()}>Clear Notifications</a></li>
    </ul>
}

export default QuickLinksDropDownContent;