import React,{ lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/css/app.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { PageLoader } from './components/utils/misc';
import { StartSentry } from './components/utils/sentry'


const BookingView            = lazy(() => import('./components/Booking/BookingView'));
const ConfirmEmail           = lazy(() => import('./components/ConfirmEmail'));
const FlightSearch           = lazy(() => import("./pages/FlightSearch"));
const FlightSearchCategories = lazy(() => import("./pages/FlightSearchCategories"));
const FlightAccountDetails   = lazy(() => import("./pages/FlightAccountDetails"));
const PaymentPage            = lazy(() => import("./pages/PaymentPage"));
const MyBookings             = lazy(() => import('./pages/MyBookings'));
const MyPassports            = lazy(() => import('./pages/MyPassports'));
const PassportInvites        = lazy(() => import('./pages/PassportInvites'));
const MyAccount              = lazy(() => import('./pages/MyAccount'));
const AboutUs                = lazy(() => import('./pages/AboutUs'));
const PageInDevelopment      = lazy(() => import('./pages/PageInDevelopment'));
const Home                   = lazy(() => import('./pages/Home'));
const SignIn                 = lazy(() => import('./pages/SignIn'));
const Register               = lazy(() => import('./pages/Register'));
const PasswordReset          = lazy(() => import('./pages/PasswordReset'));

const AdminBookingView       = lazy(() => import('./components/Admin/AdminBookingView'));
const AdminBookingsView      = lazy(() => import('./components/Admin/AdminBookingsView'));

const BookingPassports       = lazy(() => import("./pages/BookingPassports"));

StartSentry()

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<PageLoader isLoading />}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />}>

                        <Route path="/" element={<Home />} />

                        {/* AUTH */}
                        <Route path={"/signin"} element={<SignIn />} />
                        <Route path={"/register"} element={<Register />} />
                        <Route path={"/user/reset-password"} element={<PasswordReset />} />
                        <Route path={"/user/reset-password/:token"} element={<PasswordReset />} />

                        <Route path="/booking/:bookingId" element={<BookingView />} />
                        <Route path={"/user/confirm-email/:token"} element={<ConfirmEmail />} />
                        <Route path="/flights/search" element={<FlightSearch />} />
                        <Route path="/flights/searchCategories" element={<FlightSearchCategories />} />
                        <Route path="/Flights/AccountDetails" element={<FlightAccountDetails />} />
                        <Route path="/Flights/PayNow/:bookingId" element={<PaymentPage />} />
                        <Route path="/booking/:bookingId/passports" element={<BookingPassports />} />
                        <Route path="/booking" element={<MyBookings />} />

                        {/* PASSPORTS */}
                        <Route path="/passports" element={<MyPassports />} />
                        <Route path="/passports/invites" element={<PassportInvites />} />

                        <Route path="/account" element={<MyAccount />} />
                        {/* STATIC */}
                        <Route path="/AboutUs" element={<AboutUs />} />

                        {/* ADMIN */}

                        <Route path="/admin/booking/:bookingId" element={<AdminBookingView />} />
                        <Route path="/admin/booking" element={<AdminBookingsView />} />


                        <Route path='*' element={<PageInDevelopment />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
