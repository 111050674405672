import * as Sentry from "@sentry/react";
import { captureConsoleIntegration, contextLinesIntegration } from "@sentry/integrations";

export function StartSentry() {
    Sentry.init({
      dsn: "https://7116ab42fce4d41e69c89b390b14911a@sentry.avantry.io/6",
      integrations: [
        captureConsoleIntegration(),
        contextLinesIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        })
      ],
      debug: true,
      enableTracing: true,
      autoSessionTracking: true,
      release: process.env.REACT_APP_VERSION,
      environment: process.env.REACT_APP_ENV,
      tracePropagationTargets: ["localhost", "^https://.+?.avantry.io", "^https://.+?.jetfed.com"],
      
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
    
      // Session Replay
      replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
