import {Component} from "react";
import Logo from '../assets/img/jetfed-logo.svg'
import HeroImage from '../assets/img/comming-up-next.png'
import {Facebook, Instagram, Youtube} from '../assets/img/social'
import { Link } from "react-router-dom";

export default class ComingUpNext extends Component {
    render() {
        return (
            <div className="flex flex-col coming-soon-container lg:flex-row bg-base-200">
                <div className="flex-1">
                    <img
                        src={HeroImage}
                        className="object-cover object-center w-full"
                        alt={'this.props.type.imageAlt'}/>
                </div>
                <div className="pl-8 pt-8 flex-1 flex">
                    <div className="flex flex-col gap-4 py-8 pt-8 pl-8 px-4 w-full">
                        <h3 className="coming-soon-title">
                            <strong>JETFED.COM</strong> <small>v1.0 comming soon</small>
                        </h3>
                        <p className="coming-soon-paragraph pb-4">
                            JETFED is the world's first fully automated private jet booking platform.
                            Search, book and save at JETFED.
                        </p>
                        <p className="coming-soon-paragraph">
                            Morbi mollis vestibulum sollicitudin. Nunc in eros a justo facilisis rutrum. Aenean id ullamcorper libero. Vestibulum imperdiet nibh vel magna lacinia ultrices. commodo tristique. Duis lacus urna, condimentum a vehicula a, hendrerit ac nisi Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vulputate, tortor nec commodo ultricies, lectus nisl facilisis enim, vitae viverra urna nulla sed turpis.
                        </p>
                        <p className="coming-soon-paragraph">
                            Donec maximus placerat tempor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse faucibus sed dolor eget posuere. Proin tincidunt fermentum arcu. Nam ac elementum nibh. Donec facilisis interdum orci sit amet ullamcorper.
                        </p>
                        <div>

                            <a href='https://jetfed.com' className="btn btn-primary rounded-btn btn-lg my-2">Visit Website</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}