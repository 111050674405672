import {Component} from "react";
import Logo from '../assets/img/jetfed-logo.svg'
import {Facebook, Instagram, Youtube} from '../assets/img/social'
import { Link } from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <footer className="main-footer">
                <div>
                    <div className="logo">
                        <img src={Logo} alt=""/>
                    </div>
                    <div>
                        Nam urna diam, pulvinar ut purus ornare, condimentum tincidunt justo
                    </div>
                    <div className="social">
                        <a href="https://www.instagram.com/jetfedcom/"><img src={Instagram} alt="Instagram"/></a>
                        <a href="https://www.facebook.com/JetFedcom"><img src={Facebook} alt="Facebook"/></a>
                        <a href="/"><img src={Youtube} alt="YouTube"/></a>
                    </div>
                </div>
                <div>
                    <span>Help &amp; Information</span>
                    <div className="wrapper">
                        <ul>
                            <li>
                                <Link to="/AboutUs">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/help">Help</Link>
                            </li>
                            <li>
                                <Link to="/account">My Account</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/booking">Managed Bookings</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy Notice</Link>
                            </li>
                            <li>
                                <Link to="/cookie-statement">Cookie Statement</Link>
                            </li>
                            <li>
                                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <span>Quick Links</span>
                    <div className="wrapper">
                        <ul>
                            <li>
                                <Link to="/popular-countries">Popular Countries</Link>
                            </li>
                            <li>
                                <Link to="/popular-cities">Popular Cities</Link>
                            </li>
                            <li>
                                <Link to="/popular-airports">Popular Airports</Link>
                            </li>
                            <li>
                                <Link to="/all-countries">All Countries</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/notification-preferences">Notification Preferences</Link>
                            </li>
                            <li>
                                <Link to="/partners">Our Partners</Link>
                            </li>
                            <li>
                                <Link to="/languages">Languages</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        )
    }
}