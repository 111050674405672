import { useEffect, useMemo, useState, Suspense } from 'react';
import { createRoutesFromChildren, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toast";
import withRouter from '../components/utils/withRouter'

import { toast } from "react-toast";


import axios from 'axios';

import Header from "./Header";
import ApplicationVersion from "./ApplicationVersion";
import ComingUpNext from "./ComingUpNext";
import Footer from "./Footer";

import User from '../models/User'



function App(props) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers = {
        "Content-Type": "application/json",
    }

    const [currentUser, setCurrentUser] = useState(null);

    useEffect(async () => {

        if (!currentUser || !currentUser.equals(new User()))
        {
            const user = await User.getSession();
    
            if (user != null && (currentUser == null || user.userId != currentUser.userId || user.isAuthenticated != currentUser.isAuthenticated) && currentUser != new User())
            {
                setCurrentUser(user);
            }
        }
    });

    useEffect(() => {
        if (currentUser != null && props.redirectData)
        {
            if (props.redirectData.redirectErrorMessage)
            {
                toast.error(props.redirectData.redirectErrorMessage);
            }

            if (props.redirectData.redirectWarnMessage)
            {
                toast.warn(props.redirectData.redirectWarnMessage)
            }

            if (props.redirectData.redirectInfoMessage)
            {
                toast.info(props.redirectData.redirectInfoMessage)
            }

            if (props.redirectData.redirectSuccessMessage)
            {
                toast.success(props.redirectData.redirectSuccessMessage)
            }
        }
    }, [props.redirectData])

    return (
        <div className="App">
            <ToastContainer delay={5000} />
            <Header {...{ currentUser, setCurrentUser }}/>
            <Outlet context={{ currentUser, setCurrentUser }} />
            <ComingUpNext/>
            <Footer/>
            <ApplicationVersion />
        </div>
    );
}

export default withRouter(App);
